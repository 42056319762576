import './faenahome.css';
import React from 'react';
import {motion, useScroll, useTransform} from "framer-motion";
import HomeLogo from '../../assets/Foto2.png'


const Faenahome = () => {

  const {scrollYProgress} = useScroll();
  const x = useTransform(scrollYProgress, [0, 1], [0,-50]);
  const x1 = useTransform(scrollYProgress, [0, 1], [0,50]);

  return (
  <div style={{padding:0, }} className='generalContainer' >
    <div className='sectiontTitleContainer'>
        <h1 style={{fontFamily:'Roobert'}} className='sectiontTitleFaena'>Faena Art Center &gt; Main </h1>
        <h1 style={{fontFamily:'Roobert'}} className='sectiontTitleFaena'> Events </h1>
        <div className='faenaRow'>
            <div className='row2'>
                <h5 className='faenaH5'>CLIENTE</h5>
                <h7>FAENA</h7>
            </div>
            <div className='row3'>
                <h5 className='faenaH5'>AREA</h5>
                <h7>ILUMINACION</h7>
                <h7 style={{marginTop:"30px"}}>SONIDO</h7>
            </div>
        </div>
        <div className='faenaRow2'>
            <div className='row2'>
                <h5 className='faenaH5'>ROL</h5>
                <h7>DIRECCION DE ARTE</h7>
                <h7 style={{marginTop:"30px"}}>DISEÑO</h7>
            </div>
            <div className='row3'>
                <h5 className='faenaH5'>FECHA</h5>
                <h7>21/10/2023</h7>
            </div>
        </div>
    </div>
    <div className='sloganContainerFaena'>
        <img className='homeLogo2' style={{zIndex:1}} src={HomeLogo} alt="" />
    </div>
  </div>
  )
}

export default Faenahome