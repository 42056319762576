import './faenalastparagraph.css'
import React from 'react'

const FaenaLParagraph = () => {
  return (
    <section className='blur' >
      <div id='aboutus' className='generalTextContainer'>
          <div className='titleContainer'>
              <p style={{color:"#e77500", marginLeft:'0px'}} className='p1'> <a className='p1Left'></a>PROJECT</p>
              <p className='lastText' style={{color: "white", fontSize:"30px", textAlign:'left', fontFamily:'Roobert',fontWeight:'200'}}>Bonetto Sonido & Iluminacion &gt; Faena Art Center</p>
          </div>
          <div className='descriptionContainer'>
          </div>
      </div>
    </section>
  )
}

export default FaenaLParagraph