import './footer.css'
import React from 'react'
import { Link } from 'react-scroll'
import {motion, useScroll, useTransform} from "framer-motion";

const Footer = () => {
  const {scrollYProgress} = useScroll();
  const y = useTransform(scrollYProgress, [0, 0], ["0", "0%"]);

  return (
      <motion.footer style={{y}} id='contact'>
        <motion.section style={{y}} className='sectionFooter'>
          <div  className='generalContainer'>
            <h2>CONTACTANOS Y</h2>
            <h2>CONTANOS TU IDEA.</h2>
            <hr></hr>
            <div className='row'>
              <p>Sentite libre de contactarnos.</p>
                <div className='row'>
                  <div className='row2'>
                    <h5>DIRECCION</h5>
                    <h7>Balbastro 672 Parque Chacabuco, BUENOS AIRES ARGENTINA</h7>
                  </div>
                  <div style={{pointerEvents:'all'}} className='row3'>
                    <h5>CONTACTO</h5>
                    <h7>Seguinos en nuestras redes.</h7>
                    <a href="https://www.instagram.com/bonetto_sonido_e_iluminacion/" target="_blank" rel="noopener noreferrer">
                  <svg style={{marginTop:"55px", position:'absolute'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="22" height="22">
                    <g fill="#ffffff" fillRule="nonzero">
                      <g transform="scale(5.12,5.12)">
                        <path d="M16,3c-7.16752,0 -13,5.83248 -13,13v18c0,7.16752 5.83248,13 13,13h18c7.16752,0 13,-5.83248 13,-13v-18c0,-7.16752 -5.83248,-13 -13,-13zM16,5h18c6.08648,0 11,4.91352 11,11v18c0,6.08648 -4.91352,11 -11,11h-18c-6.08648,0 -11,-4.91352 -11,-11v-18c0,-6.08648 4.91352,-11 11,-11zM37,11c-1.10457,0 -2,0.89543 -2,2c0,1.10457 0.89543,2 2,2c1.10457,0 2,-0.89543 2,-2c0,-1.10457 -0.89543,-2 -2,-2zM25,14c-6.06329,0 -11,4.93671 -11,11c0,6.06329 4.93671,11 11,11c6.06329,0 11,-4.93671 11,-11c0,-6.06329 -4.93671,-11 -11,-11zM25,16c4.98241,0 9,4.01759 9,9c0,4.98241 -4.01759,9 -9,9c-4.98241,0 -9,-4.01759 -9,-9c0,-4.98241 4.01759,-9 9,-9z"></path>
                      </g>
                    </g>
                  </svg>
                </a>
                  </div>
                  
                </div>
                <div className='row4'>
                  <h5><Link to="home" spy={true} smooth={true} offstet={50} duration={500}><button className='btnContact'>TOP 🡩</button></Link></h5>
                    <h5><Link to="work" spy={true} smooth={true} offstet={50} duration={500}><button className='btnContact'>TRABAJOS</button></Link></h5>
                    <h5><Link to="work" spy={true} smooth={true} offstet={50} duration={500}><button className='btnContact'></button></Link></h5>
                    <h5><Link to="work" spy={true} smooth={true} offstet={50} duration={500}><button className='btnContact'></button></Link></h5>
                  </div>
            </div>
            <div className='lowFooter'>
              <p className='inc'>© BONETTO SONIDO & ILUMINACION S.A. 2023 All rights reserved</p>
            </div>
          </div>
        </motion.section>
      </motion.footer>

  )
}
export default Footer;